interface JobDetailDataInterface {
  id:number, 
  jobTitle:string, 
  jobType: string,
  jobLocation: string,
  jobExpiry: string,
  desTitle: string,
  desParagraph:string,
  resTitle:string,
  resList: string[],
  skillTitle: string,
  skillList: string[],
}
export const jobDetailData:JobDetailDataInterface[] = [
    {
      id: 1,
      jobTitle: "Senior React Developer",
      jobType: "Full Time",
      jobLocation: "Nepal | USA",
      jobExpiry: "June 20, 2022",
      desTitle: "Job Description",
      desParagraph:"We are looking for a great JavaScript developer who is proficient with React.js. Your primary focus will be on developing user interface components and implementing them following well-known React.js workflows (such as Context API or Redux). You will ensure that these components and the overall application are robust and easy to maintain. You will coordinate with rest of the team working on different layers of the infrastructure. Therefore, a commitment to collaborative problem solving, sophisticated design, and quality product is important.",
      resTitle: "Key Responsibilities",
      resList : [
        "Developing new user-facing features using React.js",
        "Building reusable components and front-end libraries for future use",
        "Translating designs and wireframes into high quality code",
        "Accurately estimate design tickets during planning sessions.",
        "Optimizing components for maximum performance across a vast array of web-capable devices and browsers",
        "Coordinate a small team of Junior REACT.JS Developers",
    ],
        skillTitle: "Skill & Experience",
        skillList : [
          "Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model",
          "Thorough understanding of React.js and its core principles",
          "Experience with popular React.js workflows (such as Context API or Redux)",
          "Knowledge of modern authorization mechanisms, such as JSON Web Token",
          "Experience with common front-end development tools and Libraries such as Babel, Webpack, NPM, Typescript, React Query, React Hook Forms, Tailwind CSS, HTML5, CSS3 etc.",
          "Experience with Next JS Framework",
          "Ability to understand business requirements and translate them into technical requirements",
          "Familiarity with code versioning tools such as Git",
      ],
    },
  ]
