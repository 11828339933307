import { Link } from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <section className="text-gray-600 body-font relative">
      <div className="container px-4 max-w-6xl mx-auto">
        <Seo title="404: Not found" />
          <div className="flex items-center justify-center h-[90vh]">
            <div className="p-4 space-y-4">
              <div className="flex flex-col items-start space-y-3 sm:flex-row sm:space-y-0 sm:items-center sm:space-x-3">
                <p className="font-semibold text-emerald-400 text-9xl">404</p>
                <div className="space-y-2">
                  <h1 id="pageTitle" className="flex items-center space-x-2 mt-0">
                    <svg aria-hidden="true" className="w-6 h-6 text-emerald-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                    </svg>
                    <span className="text-xl font-medium text-gray-600 sm:text-2xl dark:text-light">
                      Oops! Page not found.
                    </span>
                  </h1>
                  <p className="font-normal text-gray-600 dark:text-gray-300 text-xs">
                      You just hit a route that doesn&#39;t exist.
                  </p>
                  <p className="text-base font-normal text-gray-600 dark:text-gray-300">
                    You may return to
                    <Link to="/" className="text-emerald-400 hover:underline mx-2">Home page</Link>or try
                    using different route
                  </p>
                </div>
              </div>
            </div>
          </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
