import * as React from "react"
import { Location } from "@reach/router"
import Seo from "../../../components/seo"

import Banner from "../../../components/Banner/BannerMain"
import Layout from "../../../components/layout";
import { jobDetailData } from "../../../data/careers"
import NotFoundPage from "../../404";


function CareerDetail({ params }) {
  
  const {id} = params;
  const career = jobDetailData.find(c => c.id === Number(id))

  if (!career){
    return <NotFoundPage />
  }

  return (
    <Layout>
      <Seo title="Careers Details" />
        <section
            className="header bg-blue-100 relative  items-center flex w-full"
            >
          <div className="container px-4  max-w-6xl mx-auto">
              <div className="text-center">
                  <div className="py-10 lg:py-20">
                      <h4 className="text-xl md:text-2xl font-semibold mb-5">{career?.jobTitle}</h4>
                      <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap justify-center">
                          <div className="mt-1 flex items-center text-gray-600 text-sm mr-2">
                              <svg className="mr-1 h-4 w-4 text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd" />
                              <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
                              </svg>
                              {career?.jobType}
                          </div>
                          <div className="mt-1 flex items-center text-gray-600 text-sm mr-2">
                              <svg className="mr-1 h-4 w-4 text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                              </svg>
                              {career?.jobLocation}
                          </div>
                          <div className="mt-1 flex items-center text-sm text-gray-600 mr-2 text-blue-400">
                              <svg className="mr-1 h-4 w-4 text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                              </svg>
                              <span className="text-gray-600 mr-1">Application ends :</span> {career?.jobExpiry}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section className="relative body-font w-full pt-10 pb-3 md:pb-20 overflow-hidden">
        <div className="container px-4 max-w-6xl mx-auto">
          <div className="flex flex-wrap mb-12 ">
            <div className="w-full  mb-4 lg:mb-0 ">
              <div>
                {params["index"]}
              </div>
                <div className="mb-10"  data-aos="fade-up">
                  <h4 className="text-xl md:text-2xl font-semibold mb-5">
                    {career?.desTitle}
                  </h4>
                  <p className="leading-relaxed text-sm">
                    {career?.desParagraph}
                  </p>
                </div>
                <div className="mb-10"  data-aos="fade-up">
                  <h4 className="text-xl md:text-2xl font-semibold mb-5">
                    {career?.resTitle}
                  </h4>
                  <ul className="list-none mt-6">
                    {career?.resList?.map((detail) =>
                      <li className="py-1">
                        <div className="flex items-center">
                          <span className="inline-block py-1 px-1 mr-1">
                            <svg className="h-5 w-5 text-emerald-400" x-description="Heroicon name: solid/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                            </svg>
                          </span>
                          <div>
                            <h4 className="text-sm">{detail}</h4>
                          </div>
                        </div>
                      </li>
                      )}
                  </ul>
                </div>
                <div className="mb-10"  data-aos="fade-up">
                  <h4 className="text-xl md:text-2xl font-semibold mb-5">
                    {career?.skillTitle}
                  </h4>
                  <ul className="list-none mt-6">
                    {career?.skillList?.map((detail) =>
                      <li className="py-1">
                        <div className="flex items-center">
                          <span className="inline-block py-1 px-1 mr-1">
                            <svg className="h-5 w-5 text-emerald-400" x-description="Heroicon name: solid/check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                            </svg>
                          </span>
                          <div>
                            <h4 className="text-sm">{detail}</h4>
                          </div>
                        </div>
                      </li>
                      )}
                  </ul>
                </div>
                <div className="pt-5 border-t border-gray-300">
                  <p className="text-base font-medium text-gray-600 dark:text-gray-300">
                      Send your resume at <a href="mailto:careers@sein.tech" target="_blank" className="text-emerald-400">careers@sein.tech</a>
                  </p>
                </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CareerDetail
